const YOUTUBE_REGEXP = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
const VIMEO_REGEXP = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i

const getYoutubeId = (link = '') => {
  const match = !!link ? link.match(YOUTUBE_REGEXP) : ''
  return match && match[2].length == 11 ? match[2] : ''
}
const getVimeoId = (link = '') => {
  const match = !!link ? link.match(VIMEO_REGEXP) : ''
  return match && match[1].length > 0 ? match[1] : ''
}

export default function (url = '') {
  if (getYoutubeId(url)) {
    return {
      platform: 'YouTube',
      id: getYoutubeId(url),
      url: `https://www.youtube-nocookie.com/embed/${getYoutubeId(url)}?`,
    }
  }

  return {
    platform: 'Vimeo',
    id: getVimeoId(url),
    url: `https://player.vimeo.com/video/${getVimeoId(url)}?playsinline=0&`,
  }
}
