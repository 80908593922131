import React, { useState } from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import { Link, graphql } from 'gatsby'
import VideoPlayer from '../components/videoPlayer'
import backArrow from '../images/backarrow.png'
import _ from 'lodash'
const Content = styled.div`
  margin-top: 6.13rem;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
`

const VideoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
    height: 15rem;
  }
`
const BackArrow = styled.img`
  width: 3.86rem;
  height 1.31rem;
  object-fit: contain;
`

const Back = styled(Link)`
  position: fixed;
  top: 30px;
  left: 35px;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    top: 125px;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Control = styled.div`
  width: 0.8125rem;
  height: 1.40625rem;
  &:hover {
    cursor: pointer;
  }
  img {
    ${(props) => (props.left ? 'transform: scale(-1);' : '')}
  }
`

const VideoInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  font-style: italic;
  font-size: 30px;
  padding-left: 3vw;

  @media screen and (max-width: 768px) {
    display: none;
  }
`

const ViewMore = styled.div`
  font-size: 1rem;
  padding-right: 6vw;
`

const ViewMoreButton = styled.span`
  padding-right: 1rem;
  z-index: 300;
  &:hover {
    cursor: pointer;
  }
`

const ViewMoreContent = styled.div`
  background-color: white;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  transition: all 0.2s linear;
  top: ${(props) => (props.showViewMore ? '0' : '100%')};
`
const Thumbnails = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 30px;
`

const Tumbnail = styled.div`
  width: calc(33% - 20px);
  margin-bottom: 20px;
  margin-right: 1rem;
`

const Close = styled.div`
  text-align: right;
  padding: 0 40px;

  &:hover {
    cursor: pointer;
  }
`

const mapCategories = (cats) => {
  return cats.edges.map(({ node }) => ({
    title: node.title,
    slug: node.slug,
    videos: node.video.map((vid) => ({
      ...vid,
      thumbnail: vid.videoPreview.fixed.src,
    })),
  }))
}

const CategoryPage = ({
  data,
  pageContext = {},
  location = {
    state: {
      videoId: '',
    },
  },
}) => {
  const { slug } = pageContext
  const { allContentfulCategory } = data
  const [showViewMore, setShowViewMore] = useState(false)
  const [categories] = useState(mapCategories(allContentfulCategory))
  const [videoIndex, setVideoIndex] = useState(0)
  const currentIndex = _.findIndex(categories, (cat) => cat.slug === slug)
  const category = categories[currentIndex > -1 ? currentIndex : 0]

  const openViewMore = (index) => {
    setVideoIndex(index)
    setShowViewMore(true)
  }

  return (
    <Layout hideNav={true}>
      <Back to="/motion/">
        <BackArrow src={backArrow} />
      </Back>
      <Content>
        <div>
          {category.videos.map((vid, index) => (
            <VideoItem>
              <VideoPlayer autoplay={false} url={vid.videoUrl}></VideoPlayer>
              <VideoInfo>
                <div>{vid.category}</div>
                <ViewMore>
                  <ViewMoreButton onClick={() => openViewMore(index)}>
                    View More
                  </ViewMoreButton>
                </ViewMore>
              </VideoInfo>
            </VideoItem>
          ))}
        </div>
        <ViewMoreContent showViewMore={showViewMore}>
          <Thumbnails>
            {category.videos[videoIndex].screenshots ? (
              category.videos[videoIndex].screenshots.map((image) => {
                return (
                  <Tumbnail>
                    <img src={image.fixed.src} />
                  </Tumbnail>
                )
              })
            ) : (
              <Tumbnail>
                <img src={category.videos[videoIndex].videoPreview.fixed.src} />
              </Tumbnail>
            )}
          </Thumbnails>
          <Thumbnails>
            <div>Credits:</div>
            <article
              dangerouslySetInnerHTML={{
                __html:
                  category.videos[videoIndex].credits?.childMarkdownRemark.html,
              }}
            ></article>
          </Thumbnails>
          <Close onClick={() => setShowViewMore(false)}>Close</Close>
        </ViewMoreContent>
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CategoryQuery {
    allContentfulCategory {
      edges {
        node {
          title
          slug
          video {
            title
            id
            videoUrl
            credits {
              childMarkdownRemark {
                html
              }
            }
            screenshots {
              fixed(width: 2000) {
                src
              }
            }
            videoPreview {
              fixed(width: 2000) {
                src
              }
            }
          }
        }
      }
    }
  }
`

export default CategoryPage
