import React from 'react'
import styled from '@emotion/styled'
import getVideoPlatform from '../util/getVideoPlatform'

const PlayerContainer = styled.div`
  width: ${(props) => (props.maxWidth ? props.maxWidth : '70vw')};
  margin: 0px 3vw;
  max-height: 100vh;
  position: relative;
`
const PlayerWrapper = styled.div`
  height: 0px;
  height: 100%;
  padding-top: 56.25%;
  overflow-y: hidden;
`

const Player = styled.div`
  position: absolute;
  top: 0px;
  z-index: 100;
  bottom: 0px;
  left: 0px;
  right: 0px;
`

const VideoPlayer = ({ url, maxWidth, autoplay = true }) => {
  const video = getVideoPlatform(url)

  return (
    <PlayerContainer maxWidth={maxWidth}>
      <PlayerWrapper>
        <Player>
          <iframe
            allow="autoplay"
            frameBorder="0"
            allowFullScreen="1"
            width="100%"
            height="100%"
            src={`${video.url}${autoplay ? 'autoplay=1' : ''}`}
          ></iframe>
        </Player>
      </PlayerWrapper>
    </PlayerContainer>
  )
}

export default VideoPlayer
